import { Col, Container, Row } from "react-bootstrap"

type ErrorPageProps = {
    messages: Array<string>
}

const ErrorPage = (props: ErrorPageProps) => {
    return (
        <Container>
            <div className="d-flex flex-column full-height error-page justify-content-center">
                <Row className="d-flex justify-content-center text-center noDevices">
                    <h2>Ett fel uppstod</h2>
                    <ul>
                        {
                            props.messages.map(message => {
                                return (
                                    <li>{message}</li>
                                )
                            })
                        }
                    </ul>
                </Row>
            </div>
        </Container>
    )
}

function ErrorRow(props: ErrorPageProps) {
    return (
        <Row className='errorRow'>
            <Col className="errorCol">
                <h2>Ett fel uppstod</h2>
                <ul>
                    {
                        props.messages.map(message => {
                            return (
                                <li>{message}</li>
                            )
                        })
                    }
                </ul>
            </Col>
        </Row>
    )

}


export { ErrorPage, ErrorRow };