import { Button, Form, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { User } from "firebase/auth";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { functions } from "../../firebase/firebase";

export enum UserLevel {
    user = 0,
    superUser = 1,
    instructor = 2,
    headInstructor = 3,
    organisationAdmin = 4,
    globalAdmin = 5,
    root = 6
}

export type UserClaims = {
    level: UserLevel,
    organisation: string
}

type UserClaimsResponse = {
    claims: UserClaims
}

type EditUserModalProps = {
    visible: boolean,
    user: string | undefined,
    onAbort: () => void,
    onSave: (uid: string, claim: UserClaims) => void,
}

const EditUserModal = (props: EditUserModalProps): JSX.Element => {

    const [level, setLevel] = useState<string>("0");

    const [executeGetUserClaims, getUserClaimsExecuting, getUserClaimsError] = useHttpsCallable(
        functions,
        'getUserClaims'
    );

    useEffect(() => {
        if (props.user) {
            executeGetUserClaims({ uid: props.user }).then((result) => {
                const data = result?.data as UserClaimsResponse;
                if (data.claims !== null) {
                    setLevel(String(data.claims.level));
                }
            }).catch((reason) => {
                console.log("Error getting user claims: " + reason);
            })
        }
    }, [executeGetUserClaims, props.user])

    function handleSave(): void {

        const numLevel = Number(level);

        if (numLevel === null) {
            alert("Nivå ej angiven eller inte numerisk");
                return;
        } 
        
        if (numLevel < 0 || numLevel > 6) {
            alert("Nivå ej inom korrekt intervall");
                return;
        }

        if (props.user === undefined) {
            alert("Användare ej angiven");
                return;
        }

        const claims: UserClaims = {
            level: numLevel,
            organisation: "0"
        }
        
        props.onSave(props.user, claims);
    }

    return (
        <Modal show={props.visible} onHide={props.onAbort}>
            <Modal.Header closeButton>
                <Modal.Title>Ändra användare</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                <Form.Group className="mb-3" controlId="editUser.level">
                        <Form.Label>Behörighetsnivå</Form.Label>
                        <Form.Select value={level} onChange={(event) => {setLevel(event.target.value)}}>
                            <option key='x' hidden disabled value={'x'}>Välj nivå</option>
                            <option key={0} value={0}>{'Användare'}</option>
                            <option key={1} value={1}>{'Superanvändare'}</option>
                            <option key={2} value={2}>{'Instruktör'}</option>
                            <option key={3} value={3}>{'Huvudinstruktör'}</option>
                            <option key={4} value={4}>{'Administratör för organisation'}</option>
                            <option key={5} value={5}>{'Global administratör'}</option>
                            <option key={6} value={6}>{'Root'}</option>
                        </Form.Select>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onAbort}>
                    Avbryt
                </Button>
                <Button variant="success" onClick={handleSave}>
                    Spara
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default EditUserModal;