import { Button, Form, Modal } from "react-bootstrap";
import { auth } from '../../firebase/firebase';
import { useEffect, useState } from "react";
import { format, parse} from "date-fns";
import { useAuthState } from "react-firebase-hooks/auth";
import { QuerySnapshot } from "firebase/firestore";


export type Course = { 
    course: string,
    expiration: number,
    reminder: boolean,
    organizer: string,
    instructor: string,
    date: number,
    validFor: number,
}

type AddCourseModalProps = {
    visible: boolean,
    courseTypes: QuerySnapshot | undefined,
    onAbort: () => void,
    onSave: (course: Course) => void,
}

const AddCourseModal = (props: AddCourseModalProps): JSX.Element => {

    const [validFor, setValidFor] = useState<string>("7")
    const [date, setDate] = useState<string>("")
    const [instructor, setInstructor] = useState<string>("")
    const [organizer, setOrganizer] = useState<string>("")
    const [course, setCourse] = useState<string>("")

    const [user, loading] = useAuthState(auth);

    useEffect(() => {
        setDate(format(Date.now(), 'yyyy-MM-dd'));
    }, []);

    useEffect(() => {
        if (user && user.displayName) 
            setInstructor(user.displayName);
    }, [loading, user]);

    function handleSave() {

        const timestamp = parse(date, 'yyyy-MM-dd', new Date()).getTime();

        const selectedCourse = props.courseTypes?.docs.find(doc => doc.id === course)?.data()

        if (!selectedCourse) {
            alert("Kurstyp inte vald");
            return;
        }

        if (!organizer) {
            alert("Organisatör ej angiven");
            return;
        }

        if (!instructor) {
            alert("Instruktör ej angiven");
            return;
        }

        if (!validFor) {
            alert("Hämtningstid ej angiven");
            return;
        }

        if (!timestamp) {
            alert("Datum ej angivet");
            return;
        }

        const data: Course = {
            course: selectedCourse.course,
            organizer: organizer,
            instructor: instructor,
            date: timestamp,
            validFor: Number(validFor),
            expiration: selectedCourse.expiration,
            reminder: selectedCourse.reminder
        }

        props.onSave(data);
    }

    return (
        <Modal show={props.visible} onHide={props.onAbort}>
            <Modal.Header closeButton>
                <Modal.Title>Nytt kurstilfälle</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="addCourse.CourseType">
                        <Form.Label>Kurstyp</Form.Label>
                        <Form.Select value={undefined} onChange={(event) => {setCourse(event.target.value)}}>
                            <option key='blankChoice' hidden value={undefined}>Välj kurstyp</option>
                            {
                                props.courseTypes && props.courseTypes.docs.map(doc => {
                                    return (
                                        <option key={doc.id} value={doc.id}>{doc.data().course}</option>
                                    )
                                })
                            }
                        </Form.Select>
                    </Form.Group>
                    <Form.Group
                        className="mb-3"
                        controlId="addCourse.Organizer"
                    >
                        <Form.Label>Organisatör</Form.Label>
                        <Form.Control type="input" placeholder="Namn" value={organizer} onChange={(event) => {setOrganizer(event.target.value)}} />
                    </Form.Group>
                    <Form.Group
                        className="mb-3"
                        controlId="addCourse.Instructor"
                    >
                        <Form.Label>Instruktör</Form.Label>
                        <Form.Control type="input" placeholder="Namn" value={instructor} onChange={(event) => {setInstructor(event.target.value)}}/>
                    </Form.Group>
                    <Form.Group
                        className="mb-3"
                        controlId="addCourse.Date"
                    >
                        <Form.Label>Datum</Form.Label>
                        <Form.Control type="input" placeholder="____-__-__" value={date} onChange={(event) => {setDate(event.target.value)}}/>
                    </Form.Group>
                    <Form.Group
                        className="mb-3"
                        controlId="addCourse.Organizer"
                    >
                        <Form.Label>Hämtningstid</Form.Label>
                        <Form.Control type="input" placeholder="Dagar" value={validFor} onChange={(event) => {setValidFor(event.target.value)}} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onAbort}>
                    Avbryt
                </Button>
                <Button variant="success" onClick={handleSave}>
                    Spara
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddCourseModal;