import { faMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";

export type SearchRow = {
    searchString: string,
    search: (string: string) => void
}

const SearchBar = (props: SearchRow): JSX.Element => {

    const [serachString, setSearchString] = useState<string>("");

    useEffect(() => {
        setSearchString(props.searchString);
    }, [props.searchString])

    return (
        <InputGroup className="mb-3 search-bar">
            <InputGroup.Text id="basic-addon1">
                <FontAwesomeIcon icon={faMagnifyingGlass} />
            </InputGroup.Text>
            <Form.Control
                placeholder="Epost"
                aria-label="serach"
                autoComplete="off"
                autoCorrect="off"
                type="text"
                value={serachString}
                onChange={(e) => { setSearchString(e.target.value) }}
                onKeyUp={(target) => {
                    if (target.key === 'Enter') {
                        props.search(serachString)
                    }
                }}
            />
            <Button variant="group" id="button-addon2" onClick={()=>{props.search("")}} hidden={props.searchString === "" ? true : false}>
                Rensa
            </Button>
            <Button variant="group" id="button-addon2" onClick={()=>{props.search(serachString)}}>
                Sök
            </Button>
        </InputGroup>
    )

}

export default SearchBar;