import {
    Container,
    Row,
    Spinner,
    Col
} from 'react-bootstrap';

function FullScreenSpinner() {
    return (
        <Container>
            <div className="d-flex flex-column full-height justify-content-center">
                <Row className="d-flex justify-content-center">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </Row>
            </div>
        </Container>
    )

}

function SpinnerRow() {
    return (
        <Row className='spinnerRow'>
            <Col className="d-flex justify-content-center">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </Col>
        </Row>
    )

}

export { FullScreenSpinner, SpinnerRow };