import { OrderByDirection } from "@firebase/firestore";
import { Col, Dropdown, Row } from "react-bootstrap";

type Sort = {
    key: string,
    title: string,
    direction: OrderByDirection,
}

export type HeaderProps = {
    currentSort: Sort,
    sortOptions: Array<Sort>,
    changeSort: (sort: Sort) => void,
    sortDisabled: boolean,
    itemsPerPage: number,
    setItemsPerPage: (items: number) => void
    children?: JSX.Element | JSX.Element[];
}

const HeaderRow = (props: HeaderProps): JSX.Element => {

    function sortRowActive(sort: Sort) {
        return (sort.key === props.currentSort.key);
    }

    return (
        <Row className='buttonRow'>
            <Col className='content'>
                {
                    props.children
                }
            </Col>
            <Col className='sort' md="auto">
                <Dropdown>
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                        Sortera efter: {props.currentSort.title}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {props.sortOptions.map(sort => {
                            return (
                                <Dropdown.Item disabled={props.sortDisabled} onClick={() => { props.changeSort(sort) }} active={sortRowActive(sort)} key={sort.key}>{sort.title}</Dropdown.Item>
                            )
                        })}
                    </Dropdown.Menu>
                </Dropdown>
                <Dropdown>
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                        Träffar per sida: {props.itemsPerPage}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => { props.setItemsPerPage(5) }} key={5}>5</Dropdown.Item>
                        <Dropdown.Item onClick={() => { props.setItemsPerPage(10) }} key={10}>10</Dropdown.Item>
                        <Dropdown.Item onClick={() => { props.setItemsPerPage(25) }} key={25}>25</Dropdown.Item>
                        <Dropdown.Item onClick={() => { props.setItemsPerPage(50) }} key={50}>50</Dropdown.Item>
                        <Dropdown.Item onClick={() => { props.setItemsPerPage(100) }} key={100}>100</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Col>
        </Row>
    )

}

export default HeaderRow;