import './styles/app.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Signup from "./views/auth/signup";
import Login from "./views/auth/login";
import Forgot from "./views/auth/forgot";
import Main from './views/main';
import Console from './views/console/console';
import NotFound from './views/notFound';

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Courses from './views/console/courses';
import CourseTypes from './views/console/courseTypes';
import Users from './views/console/users';

function App() {
  return (
    <Router>
      <Routes>
      <Route path="/" element={<Main/>}/>
        <Route path="auth">
          <Route index element={<Login/>}/>
            <Route path="signup" element={<Signup/>}/>
            <Route path="forgot" element={<Forgot/>}/>
          </Route>
        <Route path="console" element={<Console/>}>
          <Route key={1} index element={<Courses/>}/>
          <Route path="courseTypes" key={2} element={<CourseTypes/>}/>
          <Route path="users" key={3} element={<Users/>}/>
        </Route>
        <Route path="*" element={<NotFound/>}/>
      </Routes>
    </Router>
  );
}

export default App;
