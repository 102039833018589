import logo from '../../images/savelife.png';
import signInWithGoogleLogo from '../../images/signInWithGoogleRounded.png';
import signInWithAppleLogo from '../../images/signInWithApple.png';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {Link, useNavigate} from "react-router-dom";
import {Container, Row, Col, Spinner} from 'react-bootstrap';
import React, {useEffect, useState} from "react";
import {auth} from "../../firebase/firebase";

import {useSignInWithEmailAndPassword, useAuthState, useSignInWithGoogle, useSignInWithApple} from 'react-firebase-hooks/auth';

import '../../styles/auth.css';
import { setSelectionRange } from '@testing-library/user-event/dist/utils';

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [signInWithEmailAndPassword, emailPasswordUser, emailPasswordLoading, emailPasswordError] = useSignInWithEmailAndPassword(auth);

    const [signInWithGoogle, googleUser, googleLoading, googleError] = useSignInWithGoogle(auth);

    const [signInWithApple, appleUser, appleLoading, appleError] = useSignInWithApple(auth);

    const [stateUser, stateLoading, stateError] = useAuthState(auth);

    const [error, setError] = useState("");

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(email, password)
    };
    useEffect(() => {
        if (stateUser && !stateLoading) {
            navigate("/console");
        }
    }, [stateUser, stateLoading]);

    useEffect(() => {
        if (emailPasswordError) {
            setError(emailPasswordError)
        }

    }, [emailPasswordError]);

    useEffect(() => {
        if (appleError) {
            setError(appleError)
        }

    }, [appleError]);

    useEffect(() => {
        if (googleError) {
            setError(googleError)
        }

    }, [googleError]);


    return (
        <Container className='d-flex flex-column justify-content-center full-height'>
            <Row className="d-flex justify-content-center">
                <Col sm="10" md="8" lg="5" xl="4" className='text-center'>

                    <form className="loginForm"
                        onSubmit={handleSubmit}>
                        <img src={logo}
                            alt="Logo"
                            width="50%"
                            className='mb-4'/>
                        <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder="Username" name="username" autoComplete='username' id='username'
                                value={email}
                                onChange={
                                    (e) => setEmail(e.target.value)
                                }/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Control type="password" placeholder="Password" autoComplete='current-password' name='password' id='password'
                                value={password}
                                onChange={
                                    (e) => setPassword(e.target.value)
                                }/>
                        </Form.Group>
                        <div className='checkbox mb-3'>
                            <Link to="/auth/signup" className='leftLink'>Sign Up</Link>
                            <Link to="/auth/forgot" className='rightLink'>Forgot Password</Link>
                        </div>
                        <Form.Group className="mb-3">
                        <Button type="submit">
                            {emailPasswordLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/> : "Sign In" }
                        </Button>
                        </Form.Group>
                        <Form.Group className="mb-3">
                        <Button className='sign-in-with-google-button' onClick={() => signInWithGoogle()}>
                        <img src={signInWithGoogleLogo} className='icon'/> Sign in with Google
                        </Button>
                        </Form.Group>
                        <Form.Group className="mb-3">
                        <Button className='sign-in-with-apple-button' onClick={() => signInWithApple()}>
                        <img className='icon' src={signInWithAppleLogo}/> Sign in with Apple
                        </Button>
                        </Form.Group>
                        {
                        error ? <div className='text-center errorMessage'>
                            {
                            error.code
                        }</div> : null
                    } </form>
                </Col>
            </Row>
        </Container>
    )
};

export default Login;
