import { User } from "firebase/auth";

type ConsoleNavNameProps = {
    user: User | null | undefined
}

const ConsoleNavName = (props: ConsoleNavNameProps): JSX.Element => {
    if (props.user) {
        if (props.user.displayName) {
            return (
                <>
                {props.user.displayName}
                </>
                );
        } else if (props.user.email) {
            return(
                <>
                {props.user.email}
                </>
                );
        } else {
            return (
                <>
                {"Användare"}
                </>
                );
        }
    }
    return (
        <>
        </>
        );
};

export default ConsoleNavName;
