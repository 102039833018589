import Button from 'react-bootstrap/Button';
import {Link} from "react-router-dom";

const NotFound = () => {

    return (
        <>
            <div class="d-flex align-items-center justify-content-center vh-100">
                <div class="text-center">
                    <h1 class="display-1 fw-bold">404</h1>
                    <p class="fs-3">
                        Resource not found.</p>
                    <p class="lead">
                        The resource you’re looking for doesn’t exist.
                    </p>
                    <Link to="/">
                        <Button>
                            Go home
                        </Button>
                    </Link>
                </div>
            </div>
        </>
    );
};

export default NotFound;
