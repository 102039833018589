/* eslint-disable no-restricted-globals */
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";


const firebaseConfig = {
    apiKey: "AIzaSyCx4f9gz0LNcZaioYCzReTzL5jx7lA_QpQ",
    authDomain: "savelife-1809e.firebaseapp.com",
    projectId: "savelife-1809e",
    storageBucket: "savelife-1809e.appspot.com",
    messagingSenderId: "710150153521",
    appId: "1:710150153521:web:e7067b8123a2b55df1fc10",
    measurementId: "G-WWEQCKLNSJ"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const firestore = getFirestore(app);
const auth = getAuth();

const functions = getFunctions(app, 'europe-west1');

if (process.env.NODE_ENV === "production") {
    console.log("In production env");
    self.FIREBASE_APPCHECK_DEBUG_TOKEN = false;
} else {
    console.log("In debug env");
    self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}

const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider('6LdHQh0lAAAAAC3H4ayQDN3wVPN2oPs9Rwy4hk9z'),
    isTokenAutoRefreshEnabled: true
})


export {
    auth,
    app,
    firestore,
    appCheck,
    functions
}