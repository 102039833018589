import { Button, Form, Modal } from "react-bootstrap";
import { auth } from '../../firebase/firebase';
import { useEffect, useState } from "react";
import { format, parse } from "date-fns";
import { useAuthState } from "react-firebase-hooks/auth";
import { QuerySnapshot } from "firebase/firestore";
import FloatingLabel from 'react-bootstrap/FloatingLabel';

interface ParticipantsModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onSubmit: (text: string) => void;
  onAbort: () => void;
  title: string;
  label: string;
  doneButton: string;
  participantList?: string[];
}

const ParticipantsModal = (props: ParticipantsModalProps): JSX.Element => {


  const [text, setText] = useState('');

  function handleTextChange(event: React.ChangeEvent<HTMLInputElement>) {
    setText(event.target.value);
  }

  return (
    <Modal show={props.isOpen} onHide={props.onAbort}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FloatingLabel controlId="textArea" label={props.label}>
          <Form.Control
            as="textarea"
            placeholder={props.label}
            style={{ height: '150px'}}
            onChange={handleTextChange}
            defaultValue={props.participantList ? props.participantList.join('\n') : ''}
          />
        </FloatingLabel>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onAbort}>
          Avbryt
        </Button>
        <Button variant="success" onClick={() => { props.onSubmit(text) }}>
          {props.doneButton}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ParticipantsModal;