import {
    Container,
    Row,
    Col,
    Card,
    ListGroup,
    Button,
    DropdownButton,
    Dropdown,
    Badge
} from 'react-bootstrap';

import { ErrorRow } from '../error';
import { useCollection } from 'react-firebase-hooks/firestore';
import { collection, query, orderBy, OrderByDirection, QueryConstraint, where, collectionGroup, getDocs, getDoc, QueryDocumentSnapshot } from 'firebase/firestore';
import { auth, firestore, functions } from '../../firebase/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { SpinnerRow } from '../spinner';
import { useEffect, useState } from 'react';
import { format } from 'date-fns'
import { useHttpsCallable } from 'react-firebase-hooks/functions';
import AddCourseModal, { Course } from './courses_addModal';
import ConfirmDialog from './elements/confirmDialog';
import exportParticipants from '../../functions/exportParticipants';
import HeaderRowTimePicker from './elements/headerTimePicker';
import exportCertificate from '../../functions/exportCertificate';
import ParticipantsModal from './participants_addModal';

type Sort = {
    key: string,
    title: string,
    direction: OrderByDirection,
}

const Courses = () => {
    // Users
    const [, userLoading,] = useAuthState(auth);

    // Sort
    const [sort, setSort] = useState<Sort>({ key: 'added', title: 'Skapad', direction: 'desc' })

    function changeSort(sort: Sort) {
        setSort(sort);
    }

    // Pagination
    const [itemsPerPage, setItemsPerPage] = useState<number>(5);

    const today: Date = new Date();
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [startDate, setStartDate] = useState<Date>(new Date(today.setDate(today.getDate() - 30)));

    const [constraints, setConstraints] = useState<QueryConstraint[]>([]);


    useEffect(() => {
        if(validateToFromDate(startDate, endDate) && startDate && endDate){
            let fromDate: Date = new Date(startDate.setHours(0, 0, 0, 0));
            let toDate: Date = new Date(endDate.setHours(23,59,59,0));
            setConstraints([where('added','>=', fromDate.getTime()), where('added', '<=', toDate.getTime())]);
        }

    }, [startDate, endDate]);


    function validateToFromDate(startDate: Date, endDate: Date){
        if(endDate < startDate){
            alert("To date cannot be smaller than From date");
            return false;
        }
        return true;
    }
    // Courses
    const [courses, coursesLoading, coursesError] = useCollection(query(collection(firestore, 'courses'), orderBy(sort.key, sort.direction), ...constraints), {
        snapshotListenOptions: {
            includeMetadataChanges: true
        }
    });

    // Course types
    const [courseTypes, ,] = useCollection(query(collection(firestore, 'courseTypes'), orderBy('course', 'desc')), {
        snapshotListenOptions: {
            includeMetadataChanges: true
        }
    });

    // Delete & Add
    const [executeDelete, deleteExecuting, deleteError] = useHttpsCallable(
        functions,
        'deleteCourse'
    );

    const [executeAdd, addExecuting, addError] = useHttpsCallable(
        functions,
        'addCourse'
    );

    function handleDeleteClick(id: string) {
        setDeleteID(id);
        setShowDeleteDialog(true);
    }

    function handleDeleteConfirm() {
        setShowDeleteDialog(false);
        executeDelete({ id: deleteID }).then((result) => {
            console.log(result);
        }).catch((error) => {
            alert(error);
        })
    }

    async function getParticipants(courseId: string) {

        const q = query(collectionGroup(firestore, 'certificates'), where('courseId', '==', courseId));
      
        const querySnapshot = await getDocs(q);
      
        let participants: string[] = [];
      
        console.log(querySnapshot.docs);
      
        for (const doc of querySnapshot.docs) {
      
            const parrent = doc.ref.parent.parent;
      
            if (parrent) {
      
                const parrentUser = await getDoc(parrent);
      
                const parrentUserData = parrentUser.data();
      
                if (parrentUserData) {
                    participants.push(parrentUserData.name);
                } else {
                    console.log("Error: parrent data is null");
                }
      
            } else {
                console.log("Error: parrent is null");
            }
      
        }
        return {participants: participants};
    }
    const [participantsIsOpen, setParticipantsOpen] = useState(false);
    const [participantList, setParticipantList] = useState<string[]>();
    const [currentCourse, setCurrentCourse] = useState<QueryDocumentSnapshot>();
    const handleParticipantsModalSubmit = (text: string) => {
        let participantListArray = text.split("\n");
        participantListArray = participantListArray.filter(value => value !== "");
        if(currentCourse && participantList){
            exportCertificate(currentCourse.id, format(currentCourse.data().date, 'yyyy-MM-dd'), participantListArray)
        }
        console.log('Text entered: ', text);
        setParticipantsOpen(false);
    };

    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const [deleteID, setDeleteID] = useState<string>("")
    const handleCloseDeleteDialog = () => setShowDeleteDialog(false);

    const [showAddDialog, setShowAddDialog] = useState<boolean>(false);
    const handleCloseAddDialog = () => setShowAddDialog(false);


    function handleAddClick() {
        setShowAddDialog(true);
    }

    function handleSaveCourse(data: Course) {
        console.log(data);
        setShowAddDialog(false);
        executeAdd(data).then((result) => {
            if (!result) {
                alert("Error");
            }
        }).catch((error) => {
            alert("Error: " + error);
        })
    }

    useEffect(() => {
        if (deleteError) {
            alert(deleteError);
        }

    }, [deleteError]);

    useEffect(() => {
        if (addError) {
            alert(addError);
        }

    }, [addError]);

    function MainPage() {
        return (
            <>
                <div className='menu-spacer'></div>
                <Container className='courseListContainer'>
                    <HeaderRowTimePicker currentSort={sort} sortOptions={[
                        { key: 'added', title: 'Skapad', direction: 'desc' },
                        { key: 'date', title: 'Kursdatum', direction: 'desc' },
                        { key: 'organizer', title: 'Organisatör', direction: 'asc' },
                        { key: 'instructor', title: 'Instruktör', direction: 'asc' }]}
                        changeSort={changeSort} startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}><Button variant="success" onClick={handleAddClick}>Nytt kurstilfälle</Button></HeaderRowTimePicker>
                    <Row>
                        <Col>
                            <CourseList />
                        </Col>
                    </Row>
                </Container>
                <ConfirmDialog visible={showDeleteDialog} onAbort={handleCloseDeleteDialog} onDelete={handleDeleteConfirm} title={'Bekräfta'} message={'Är du säker på att du vill ta bort kurstilfället?'} />
                <AddCourseModal visible={showAddDialog} onAbort={handleCloseAddDialog} onSave={handleSaveCourse} courseTypes={courseTypes} />
                <ParticipantsModal
                    isOpen={participantsIsOpen}
                    onRequestClose={() => { setParticipantsOpen(false); } }
                    onSubmit={handleParticipantsModalSubmit} 
                    onAbort={() => { setParticipantsOpen(false); } } title={'Exportera intyg'} label={'Kursdeltagare'} doneButton={'Exportera'} participantList={participantList}/>
            </>
        )
    }

    function CourseList() {
        if (coursesError) {
            return (<ErrorRow messages={[coursesError.message]} />);
        } else if (userLoading || coursesLoading || deleteExecuting || addExecuting) {
            return (<SpinnerRow />);
        } else if (courses != null && courses.docs.length > 0) {
            return (
                <Card>
                    <ListGroup variant="flush">
                        {
                            courses.docs.map(doc => {
                                return (
                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-center"
                                        id={
                                            doc.id
                                        }
                                        key={
                                            doc.id
                                        }>
                                        <div>
                                            <div className='courseTitle'>
                                                <div className="courseName">
                                                    {doc.data().course}
                                                </div>
                                                <div className="courseCode">
                                                    <Badge className='cc-badge'>
                                                        {doc.data().code}
                                                    </Badge>
                                                </div>
                                            </div>
                                            <div className="courseDate">
                                                {format(doc.data().date, 'yyyy-MM-dd')} - {doc.data().instructor}
                                            </div>
                                            <div className="courseOrganizer">
                                                {doc.data().organizer}
                                            </div>
                                        </div>
                                        <div>
                                            <DropdownButton id="dropdown-basic-button" title="Åtgärder">
                                            <Dropdown.Item onClick={async () => {
                                                        const { participants } = await getParticipants(doc.id);
                                                        setParticipantList(participants);
                                                        setCurrentCourse(doc);
                                                        setParticipantsOpen(true);
                                                    }
                                                }>Exportera Intyg</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { exportParticipants(doc.id) }}>Exportera Excel</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { handleDeleteClick(doc.id) }} >Radera</Dropdown.Item>
                                            </DropdownButton>
                                        </div>
                                    </ListGroup.Item>

                                )
                            })
                        } </ListGroup>
                </Card>
            )
        } else {
            return (
                <Row className='spinnerRow'>
                    <Col className="d-flex justify-content-center">
                        Inga kurstilfällen
                    </Col>
                </Row>
            )
        }
    }

    return (
        <MainPage />
    );

};

export default Courses;
