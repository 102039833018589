import { Button, Form, Modal } from "react-bootstrap";
import { useState } from "react";
import { QuerySnapshot } from "firebase/firestore";


export type CourseType = {
    course: string,
    expiration: number,
    reminder: boolean
}

type AddCourseTypeModalProps = {
    visible: boolean,
    courseTypes: QuerySnapshot | undefined,
    onAbort: () => void,
    onSave: (course: CourseType) => void,
}

const AddCourseTypeModal = (props: AddCourseTypeModalProps): JSX.Element => {

    const [expiration, setExpiration] = useState<string>("365");
    const [reminder, setReminder] = useState<boolean>(true);
    const [course, setCourse] = useState<string>("");

    function handleSave(): void {

        if (!course) {
            alert("Namn ej angivet");
            return;
        }

        const courseTypes = props.courseTypes?.docs;

        if (courseTypes != null) {
            if (courseTypes.some(e => e.data().course === course)) {
                alert("En kurstyp med det aktuella namnet finns redan");
                return;
            }
        }

        const expirationNumber = Number(expiration);

        if (!expirationNumber) {
            alert("Giltlighet ej angivet eller ej numeriskt");
            return;
        }

        const data: CourseType = {
            course: course,
            expiration: Number(expiration),
            reminder: reminder
        }

        props.onSave(data);
    }

    return (
        <Modal show={props.visible} onHide={props.onAbort}>
            <Modal.Header closeButton>
                <Modal.Title>Ny kurstyp</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group
                        className="mb-3"
                        controlId="addCourse.Course"
                    >
                        <Form.Label>Kursnamn</Form.Label>
                        <Form.Control type="input" placeholder="Namn" value={course} onChange={(event) => { setCourse(event.target.value) }} />
                    </Form.Group>
                    <Form.Group
                        className="mb-3"
                        controlId="addCourse.Expiration"
                    >
                        <Form.Label>Certifikatets giltlighet</Form.Label>
                        <Form.Control type="input" placeholder="Dagar" value={expiration} onChange={(event) => { setExpiration(event.target.value) }} />
                    </Form.Group>
                    <Form.Group
                        className="mb-3"
                        controlId="addCourse.Reminder"
                    >
                        <Form.Label>Påminnelse</Form.Label>
                        <Form.Check checked={reminder} onChange={() => { setReminder(!reminder) }} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onAbort}>
                    Avbryt
                </Button>
                <Button variant="success" onClick={handleSave}>
                    Spara
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddCourseTypeModal;