import kioskerLogo from '../../images/kiosker.svg';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {Link, useNavigate} from "react-router-dom";
import {Container, Row, Col, Spinner} from 'react-bootstrap';
import {useCreateUserWithEmailAndPassword, useAuthState} from 'react-firebase-hooks/auth';
import {auth} from "../../firebase/firebase";
import {useState, useEffect} from 'react';

import '../../styles/auth.css';


const Signup = () => {


    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [terms, setTerms] = useState('');
    const [createUserWithEmailAndPassword, user, loading, error,] = useCreateUserWithEmailAndPassword(auth);
    const [stateUser, stateLoading, stateError] = useAuthState(auth);

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            console.log("Password doesn't match")
            return
        }
        createUserWithEmailAndPassword(email, password)
    };

    useEffect(() => {
        if (stateUser && !stateLoading) {
            navigate("/console");
        }
    }, [stateUser, stateLoading]);

    return (
        <Container className='d-flex flex-column full-height justify-content-center'>
            <Row className="d-flex justify-content-center">
                <Col sm="10" md="8" lg="5" xl="4">

                    <form className="loginForm" autoComplete='on'
                        onSubmit={handleSubmit}>
                        <div className='text-center'>
                            <img src={kioskerLogo}
                                alt="Kiosker logo"
                                width="50%"
                                className='mb-4'/>
                        </div>
                        <Form.Group className="mb-3">
                            <Form.Control type="email" name='email' placeholder="Email" required
                                value={email}
                                onChange={
                                    (e) => setEmail(e.target.value)
                                }/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Control type="text" name='name' placeholder="Name" id='name' autoComplete='name' required
                                value={name}
                                onChange={
                                    (e) => setName(e.target.value)
                                }/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Control type="password" autoComplete="new-password" name='password' placeholder="Password" id='new-password' required
                                value={password}
                                onChange={
                                    (e) => setPassword(e.target.value)
                                }/>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Control type="password" placeholder="Confirm Password" required
                                value={confirmPassword}
                                onChange={
                                    (e) => setConfirmPassword(e.target.value)
                                }/>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Check type="checkbox" label="I accept the terms" name='accept-terms' id='accept-terms' required
                                value={terms}
                                onChange={
                                    (e) => setTerms(e.target.value)
                                }/>
                        </Form.Group>
                        <div className='checkbox mb-3'>
                            <Link to="/auth" className='leftLink'>Sign In</Link>
                            <Link to="/auth/forgot" className='rightLink'>Forgot Password</Link>
                        </div>
                        <Button type="submit">
                            {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/> : "Sign Up" }
                        </Button>
                        {
                        error ? <div className='text-center errorMessage'>{error.code}</div> : null
                    } </form>
                </Col>
            </Row>
        </Container>
    )
};

export default Signup;
