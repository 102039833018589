import * as XLSX from 'xlsx'
import * as ExcelJS from 'exceljs'
import { firestore } from '../firebase/firebase';
import { query, where, getDocs, collectionGroup, getDoc } from "firebase/firestore";
import { saveAs } from 'file-saver';

type Participant = {
    name: string | undefined,
    email: string | undefined,
    claimed: Date | undefined,
}

async function exportParticipants(courseId: string) {

    console.log("Course ID: " + courseId);

    // where('certificates.courseId', '==', courseId)

    const q = query(collectionGroup(firestore, 'certificates'), where('courseId', '==', courseId));

    const querySnapshot = await getDocs(q);

    let participants: Participant[] = [];

    console.log(querySnapshot.docs);

    for (const doc of querySnapshot.docs) {

        let participant: Participant = { name: undefined, email: undefined, claimed: undefined }

        const parrent = doc.ref.parent.parent;
        const courseData = doc.data();

        if (parrent) {

            const parrentUser = await getDoc(parrent);

            const parrentUserData = parrentUser.data();

            if (parrentUserData) {
                participant.email = parrentUserData.email;
                participant.name = parrentUserData.name;
            } else {
                console.log("Error: parrent data is null");
            }

            participant.claimed = new Date(courseData.date);

            console.log(participant);
            participants.push(participant);
            console.log(participants);

        } else {
            console.log("Error: parrent is null");
        }

    };

    console.log(participants);

    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Deltagare');

    sheet.columns = [
        { header: 'Namn', key: 'name', width: 25, alignment: { horizontal: 'right' } },
        { header: 'Email', key: 'email', width: 35, alignment: { horizontal: 'left' } },
        { header: 'Hämtat', key: 'claimed', width: 15, alignment: { horizontal: 'left' } }
    ];

    sheet.getRow(1).font = {
        bold: true,
    }

    /*
        for(var i = 0; i < 100; i++){
            sheet.addRows(participants);
        }
    */
    sheet.addRows(participants);
    sheet.getColumn('A').alignment = { horizontal: 'left' };
    sheet.getColumn('B').alignment = { horizontal: 'left' };
    sheet.getColumn('C').alignment = { horizontal: 'left' };

    sheet.pageSetup.printTitlesColumn = '1:1';
    sheet.views = [
        { state: 'frozen', xSplit: 3, ySplit: 1, zoomScale: 150 }
    ];

    workbook.xlsx.writeBuffer().then(function (buffer) {
        console.log(buffer);
        saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            `participants.xlsx`
        );
    });

    workbook.xlsx.writeFile('test.xlsx');


    //await workbook.xlsx.writeFile("test.xlsx");

    /*
    // const workbook = XLSX.utils.book_new();

    // Convert the user data to a worksheet
    //const worksheet = XLSX.utils.json_to_sheet(participants);

    // Header
    const header = ['Namn', 'Email', 'Hämtad'];
    const headerStyle = {
        font: { bold: true },
    };
    XLSX.utils.sheet_add_aoa(worksheet, [header], { origin: 'A1' });
    XLSXSTYLE.utils.sheet_set_range_style(worksheet, 'A1:C1', headerStyle);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Deltagare');

    // Save the workbook to a file
    XLSX.writeFile(workbook, 'deltagare.xlsx');
    */

}

export default exportParticipants;