import {auth} from "../firebase/firebase";
import {useAuthState} from "react-firebase-hooks/auth";
import {useNavigate} from "react-router-dom";
import React, {useEffect} from "react";
import {FullScreenSpinner} from "./spinner";


const Main = () => {

    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(() => {

        if (!loading) {
            if (user) {
                navigate("/console");
            } else {
                navigate("/auth");
            }
        }

    }, [user, loading, navigate]);

    return (
        <>
            <FullScreenSpinner />
        </>
    );
};

export default Main;
