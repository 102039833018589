import {
    Container,
    Row,
    Col,
    Card,
    ListGroup,
    DropdownButton,
    Dropdown,
    Badge
} from 'react-bootstrap';

import { ErrorRow } from '../error';

import { useCollection } from 'react-firebase-hooks/firestore';
import { collection, query, orderBy, OrderByDirection, limit, startAfter, QueryDocumentSnapshot, QueryConstraint, endBefore, getDocs, limitToLast, where, startAt } from 'firebase/firestore';
import { auth, firestore, functions } from '../../firebase/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { SpinnerRow } from '../spinner';
import { useEffect, useState } from 'react';
import { format } from 'date-fns'
import { useHttpsCallable } from 'react-firebase-hooks/functions';
import ConfirmDialog from './elements/confirmDialog';
import PaginationRow from './elements/pagination';
import HeaderRow from './elements/header';
import EditUserModal, { UserClaims, UserLevel } from './user_editModal';
import SearchBar from './elements/search';


type Sort = {
    key: string,
    title: string,
    direction: OrderByDirection,
}

const Users = () => {
    // User
    const [, userLoading,] = useAuthState(auth);

    // Sort
    const [sort, setSort] = useState<Sort>({ key: 'created', title: 'Skapat', direction: 'desc' })

    function changeSort(sort: Sort) {
        setConstraints([limit(itemsPerPage)]);
        setSort(sort);
    }

    // Pagination
    const [itemsPerPage, setItemsPerPage] = useState<number>(10);

    const [pageNumber, setPageNumber] = useState<number>(1);
    const [firstDocs, setFirstDocs] = useState<QueryDocumentSnapshot[]>([]);
    const [lastDocs, setLastDocs] = useState<QueryDocumentSnapshot[]>([]);

    const [constraints, setConstraints] = useState<QueryConstraint[]>([limit(itemsPerPage)]);

    const [canGoNext, setCanGoNext] = useState<boolean>(false);

    function next() {

        if (lastDocs) {
            setConstraints([startAfter(lastDocs[pageNumber]), limit(itemsPerPage)])
            setPageNumber(pageNumber + 1);
        } else {
            console.log("Next error");
        }
    }

    function prev() {

        if (firstDocs) {
            if (pageNumber - 1 <= 1) {
                console.log("Current page is 1, reset pagination");
                setConstraints([limit(itemsPerPage)]);
                setPageNumber(1);
            } else {
                setConstraints([startAt(firstDocs[pageNumber - 1]), limit(itemsPerPage)])
                setPageNumber(pageNumber - 1);
            }
        } else {
            console.log("Prev error");
        }

    }

    // Email search
    const [searchFilter, setSearchFilter] = useState<string>("");
    const [filter, setFilter] = useState<QueryConstraint[]>([]);

    useEffect(() => {

        if (searchFilter === "") {
            setFilter([]);
        } else {
            setSort({ key: 'email', title: 'Adress', direction: 'asc' });
            setFilter([where('email', '>=', searchFilter), where('email', '<=', searchFilter + '\uf8ff')]);
        }

    }, [searchFilter])

    // Users

    const [users, usersLoading, usersError] = useCollection(query(collection(firestore, 'users'), ...filter, orderBy(sort.key, sort.direction), ...constraints), {
        snapshotListenOptions: {
            includeMetadataChanges: true
        }
    });

    // Pagination
    useEffect(() => {
        async function updatePaginationPossibilities(lastDoc: QueryDocumentSnapshot) {

            // Check if we can go forward
            const nextItemQuery = query(collection(firestore, 'users'), ...filter, orderBy(sort.key, sort.direction), startAfter(lastDoc), limit(1));
            const nextSnapshot = await getDocs(nextItemQuery);
            const nextItemLength = nextSnapshot.docs.length
            if (nextItemLength > 0) {
                setCanGoNext(true);
            } else {
                setCanGoNext(false);
            }
        };
        if (users) {
            const firstDoc = users.docs[0];
            let arrayFirst = firstDocs;

            arrayFirst[pageNumber] = firstDoc;
            setFirstDocs(arrayFirst);

            const lastDoc = users.docs[users.docs.length - 1];
            let arrayLast = lastDocs;
            arrayLast[pageNumber] = lastDoc;
            setLastDocs(arrayLast);

            updatePaginationPossibilities(lastDoc);

        }
    }, [users, firstDocs, lastDocs, pageNumber, sort.direction, sort.key, filter]);

    useEffect(() => {
        setConstraints([limit(itemsPerPage)]);
    }, [itemsPerPage]);

    // Actions

    const [executeSetUserClaims, setUserClaimsExecuting, setUserClaimsError] = useHttpsCallable(
        functions,
        'setUserClaims'
    );

    function setUserClaims(uid: string, claims: UserClaims) {
        executeSetUserClaims({ uid: uid, claims: claims }).then((result) => {
            console.log(result);
        }).catch((reason) => {
            console.log("Error settings user claims: " + reason);
        })
    }

    // Delete
    const [executeDelete, deleteExecuting, deleteError] = useHttpsCallable(
        functions,
        'deleteUser'
    );

    function handleDeleteClick(id: string) {
        setDeleteID(id);
        setShowDeleteDialog(true);
    }

    function handleDeleteConfirm() {
        setShowDeleteDialog(false);
        executeDelete({ uid: deleteID })
    }

    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const [deleteID, setDeleteID] = useState<string>("");
    const handleCloseDeleteDialog = () => setShowDeleteDialog(false);


    // Edit
    const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
    const [editUser, setEditUser] = useState<string | undefined>(undefined);
    const handleCloseEditDialog = () => { setShowEditDialog(false) };


    function handleEditClick(id: string) {
        setEditUser(id);
        setShowEditDialog(true);
    }

    function handleSaveUser(uid: string, claims: UserClaims) {
        setShowEditDialog(false);
        setUserClaims(uid, claims);
    }

    function UserLevelString(props: { level: UserLevel }) {
        switch (props.level) {
            case UserLevel.user:
                return (<>Användare</>)
            case UserLevel.superUser:
                return (<>Superanvändare</>)
            case UserLevel.instructor:
                return (<>Instruktör</>)
            case UserLevel.headInstructor:
                return (<>Huvudinstruktör</>)
            case UserLevel.organisationAdmin:
                return (<>Administratör för organisation</>)
            case UserLevel.globalAdmin:
                return (<>Global administratör</>)
            case UserLevel.root:
                return (<>Root</>)
        }
    }

    function UserItems() {
        return (
            <>
                {
                    users?.docs.map(user => {
                        return (
                            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-center"
                                id={
                                    user.data().id
                                }
                                key={
                                    user.data().id
                                }>
                                <div>
                                    <div className="info">
                                        <span className='email'>
                                            {user.data().email}
                                        </span>
                                        {
                                            user.data().name ? (<span className='name'>{' (' + user.data().name + ')'}</span>) : (null)
                                        }
                                    </div>
                                    <div className="dates">
                                        {
                                            user.data().created ? (
                                                <>
                                                    {'Kontot skapat: '}
                                                    {format(user.data().created, 'yyyy-MM-dd')}
                                                </>
                                            ) : (null)
                                        }
                                    </div>
                                    <div className='badges'>
                                    {
                                            user.data().level > UserLevel.user ? (
                                                <Badge bg="danger" className='user-level'>
                                                    <UserLevelString level={user.data().level} />
                                                </Badge>
                                            ) : (null)
                                        }
                                    </div>
                                </div>
                                <div>
                                    <DropdownButton id="dropdown-basic-button" title="Åtgärder">
                                        <Dropdown.Item onClick={() => { handleEditClick(user.id) }} >Redigera</Dropdown.Item>
                                        <Dropdown.Item onClick={() => { handleDeleteClick(user.id) }} >Radera</Dropdown.Item>
                                    </DropdownButton>
                                </div>
                            </ListGroup.Item>

                        )
                    })
                } </>
        )
    }

    function MainPage() {
        return (
            <>
                <div className='menu-spacer'></div>
                <Container className='usersListContainer'>
                    <HeaderRow currentSort={sort} sortOptions={[
                        { key: 'created', title: 'Skapat', direction: 'desc' },
                        { key: 'email', title: 'Adress', direction: 'asc' },
                        { key: 'level', title: 'Behörighet', direction: 'desc' }
                    ]}
                    changeSort={searchFilter === "" ? changeSort : ((sort) => { })} itemsPerPage={itemsPerPage} setItemsPerPage={setItemsPerPage} sortDisabled={searchFilter === "" ? false : true}>
                            <SearchBar search={setSearchFilter} searchString={searchFilter} />
                    </HeaderRow>
                    <Row>
                        <Col>
                            <UserList />
                        </Col>
                    </Row>
                    <PaginationRow canGoPrev={pageNumber <= 1 ? false : true} canGoNext={canGoNext} onNext={next} onPrev={prev} />
                </Container>
                <ConfirmDialog visible={showDeleteDialog} onAbort={handleCloseDeleteDialog} onDelete={handleDeleteConfirm} title={'Bekräfta'} message={'Är du säker på att du vill ta bort användaren?'} />
                <EditUserModal visible={showEditDialog} onAbort={handleCloseEditDialog} onSave={handleSaveUser} user={editUser} />
            </>
        )
    }

    function UserList() {
        if (usersError) {
            return (<ErrorRow messages={[usersError.message]} />);
        } else if (userLoading || usersLoading || deleteExecuting || setUserClaimsExecuting) {
            return (<SpinnerRow />);
        } else if (users && users.docs.length > 0) {
            return (
                <>
                    <Card>
                        <ListGroup variant="flush">
                            <UserItems />
                        </ListGroup>
                    </Card>
                </>
            );
        } else {
            return (
                <Row className='spinnerRow'>
                    <Col className="d-flex justify-content-center">
                        Inga användare
                    </Col>
                </Row>
            )
        }
    }

    return (
        <MainPage />
    );

};

export default Users;
