import { Button, Modal } from "react-bootstrap";


type DeleteCourseModalProps = {
    visible: boolean,
    title: string,
    message: string,
    onAbort: () => void,
    onDelete: () => void,
}

const ConfirmDialog = (props: DeleteCourseModalProps): JSX.Element => {

    function handleDelete() {
        props.onDelete();
    }

    return (
        <Modal show={props.visible} onHide={props.onAbort}>
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{props.message}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onAbort}>
                    Avbryt
                </Button>
                <Button variant="danger" onClick={handleDelete}>
                    Radera
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ConfirmDialog;