import { faChevronLeft, faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Row } from "react-bootstrap";

import '../../../styles/pagination.css';

export type PaginationProps = {
    canGoPrev: boolean,
    canGoNext: boolean,
    onNext: () => void,
    onPrev: () => void,
}

const PaginationRow = (props: PaginationProps): JSX.Element => {
    return (
        <Row>
            <Col className='paginationRow'>
                <Button
                    variant='light'
                    disabled={!props.canGoPrev}
                    onClick={props.onPrev}
                >
                    <span className='content'>
                        <FontAwesomeIcon icon={faChevronLeft} />
                        <span>Föregående</span>
                    </span>
                </Button>
                <Button
                    variant='light'
                    disabled={!props.canGoNext}
                    onClick={props.onNext}
                >
                    <span className='content'>
                        <span>Nästa</span>
                        <FontAwesomeIcon icon={faChevronRight} />
                    </span>
                </Button>
            </Col>
        </Row>
    )

}

export default PaginationRow;