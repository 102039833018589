import { OrderByDirection } from "@firebase/firestore";
import { Button, Col, Dropdown, Row } from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { forwardRef, useEffect, useState } from "react";
import sv from 'date-fns/locale/sv';
// import '../../../styles/header.css';

type Sort = {
    key: string,
    title: string,
    direction: OrderByDirection,
}

export type HeaderRowTimePickerProps = {
    currentSort: Sort,
    sortOptions: Array<Sort>,
    changeSort: (sort: Sort) => void,
    children?: JSX.Element | JSX.Element[],
    startDate?: Date,
    endDate?: Date,
    setStartDate?: (date: Date) => void,
    setEndDate?: (date: Date) => void
}

const HeaderRowTimePicker = (props: HeaderRowTimePickerProps): JSX.Element => {
    const today: Date = new Date();
    const [rangeStart, setRangeStart] = useState(new Date(today.setDate(today.getDate() - 7)));
    const [rangeEnd, setRangeEnd] = useState(new Date());

    useEffect(() => {
        registerLocale('sv', sv);
    }, [])

    const selectDateFromHandler = (d: Date) => {
        setRangeStart(d)
        if (props.setStartDate) {
            props.setStartDate(d);
        }
    }

    const selectDateToHandler = (d: Date) => {
        setRangeEnd(d)
        if (props.setEndDate) {
            props.setEndDate(d);
        }
    }

    function sortRowActive(sort: Sort) {
        return (sort.key === props.currentSort.key);
    }

    const DateInput = forwardRef((props: any, ref) => (
        <Col className="datePickerButton">
            <Button variant="light" {...props} ref={ref}>{props.label + ' ' + props.value}</Button>
        </Col>
    ));

    return (
        <Row className='buttonRow'>
            <Col className='content'>
                {
                    props.children
                }
            </Col>
            <Col className='sort' md="auto">
                <DatePicker
                    dateFormat="yyyy-MM-dd"
                    selected={props.startDate}
                    onChange={selectDateFromHandler}
                    locale="sv"
                    customInput={<DateInput label={"Från:"}  />}
                    todayButton={"Idag"}
                    withPortal 
                    />
                <DatePicker
                    dateFormat="yyyy-MM-dd"
                    selected={props.endDate}
                    onChange={selectDateToHandler}
                    locale="sv"
                    customInput={<DateInput label={"Till:"} />}
                    todayButton={"Idag"} 
                    withPortal
                    />
                <Dropdown>
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                        Sortera efter: {props.currentSort.title}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {props.sortOptions.map(sort => {
                            return (
                                <Dropdown.Item onClick={() => { props.changeSort(sort) }} active={sortRowActive(sort)}>{sort.title}</Dropdown.Item>
                            )
                        })}
                    </Dropdown.Menu>
                </Dropdown>
            </Col>
        </Row>
    )

}

export default HeaderRowTimePicker;