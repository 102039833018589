import { Link, Outlet } from 'react-router-dom';
import {
    Container,
    Nav,
    Navbar,
    NavDropdown
} from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase/firebase";
import { signOut } from 'firebase/auth';
import { useAuthState } from "react-firebase-hooks/auth";
import { useEffect, useState } from "react";
import '../../styles/console.css';
import ConsoleNavName from './consoleNavName';
import { useLocation } from 'react-router-dom'

const logo = require('../../images/savelife-small.png');



const Console = () => {

    const [user, loading,] = useAuthState(auth);

    const [expanded, setExpanded] = useState(false);

    const navigate = useNavigate();

    const logOut = () => {
        signOut(auth);
    };

    let location = useLocation();

    useEffect(() => {
        console.log("Loaction: " + location.pathname)
    }, [location]);

    useEffect(() => {
        if (!loading && !user)
            return navigate("/auth");
    }, [loading, user, navigate]);

    function active(path: string) {
        return (location.pathname === path);
    }

    return (
        <>
            <Navbar bg="light" expand="lg" fixed="top" expanded={expanded}>
                <Container>
                    <Navbar.Brand>
                        <img src={logo}
                            className="logo"
                            alt="Savlife logo" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : true)} />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">


                            <NavDropdown title="Utbildning" id="nav-dropdown">
                                <NavDropdown.Item as={Link} to="/console" active={active("/console")}>Kurstilfällen</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/console/courseTypes" active={active("/console/courseTypes")}>Kurstyper</NavDropdown.Item>
                            </NavDropdown>

                            <Nav.Link as={Link} onClick={() => setExpanded(false)}
                                to="/console/users">Användare</Nav.Link>
                        </Nav>
                        <Nav>
                            <NavDropdown title={<ConsoleNavName user={user} />}
                                id="basic-nav-dropdown"
                                className='justify-content-end'>
                                <NavDropdown.Item href="#" onClick={() => setExpanded(false)}>Konto</NavDropdown.Item>
                                <NavDropdown.Item onClick={logOut}>Logga ut</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Outlet />
        </>
    );
};

export default Console;
