import kioskerLogo from '../../images/kiosker.svg';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {Link, useNavigate} from "react-router-dom";
import {Container, Row, Col, Spinner} from 'react-bootstrap';

import {auth} from '../../firebase/firebase';
import {useSendPasswordResetEmail, useAuthState} from 'react-firebase-hooks/auth';

import { useState, useEffect } from 'react';

import '../../styles/auth.css';

const Forgot = () => {
    const [email, setEmail] = useState('');
    const [sendPasswordResetEmail, sending, error] = useSendPasswordResetEmail(auth);

    const [stateUser, stateLoading, stateError] = useAuthState(auth);

    const handleSubmit = async (e) => {
        e.preventDefault();
        sendPasswordResetEmail(email)
    };

    const navigate = useNavigate();

    useEffect(() => {
        if (stateUser && !stateLoading) {
            navigate("/console");
        }
    }, [stateUser, stateLoading]);

    return (
        <Container className='d-flex flex-column full-height justify-content-center'>
            <Row className="d-flex justify-content-center">
                <Col sm="10" md="8" lg="5" xl="4" className='text-center'>

                    <form className="loginForm" autoComplete='on' onSubmit={handleSubmit}>
                        <img src={kioskerLogo}
                            alt="Kiosker logo"
                            width="50%"
                            className='mb-4'/>
                        <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder="Email" autoComplete='email' name='email' id='email' required
                                value={email}
                                onChange={
                                    (e) => setEmail(e.target.value)
                                }/>
                        </Form.Group>
                        <div className='checkbox mb-3'>
                            <Link to="/auth/signup" className='leftLink'>Sign Up</Link>
                            <Link to="/auth" className='rightLink'>Sign In</Link>
                        </div>
                        <Button type="submit">
                            {sending ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/> : "Reset Password" }
                        </Button>
                        {
                        error ? <div className='text-center errorMessage'>{error.code}</div> : null
                    }
                    </form>
                </Col>
            </Row>
        </Container>
    )
};

export default Forgot;
